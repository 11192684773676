// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .options-page_qtn26{position:relative;flex:1 0 auto;padding:16px 32px 24px}html.app__layout--desktop .options-page__title_qX8gq{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal;margin-bottom:8px;color:var(--TextDefault);text-align:center}html.app__layout--desktop .options-page__description_bfN_N{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;margin-bottom:24px;color:var(--TextPrimary);text-align:center}html.app__layout--desktop .options-page__dialog_Gjt1Q{margin-bottom:32px}html.app__layout--desktop .options-page__list_XXc6G{display:grid;grid-template-rows:1fr 1fr;grid-template-columns:1fr 1fr;gap:16px}html.app__layout--desktop .options-page__button-container_uX8y2{padding:24px 32px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"options-page": `options-page_qtn26`,
	"options-page__title": `options-page__title_qX8gq`,
	"options-page__description": `options-page__description_bfN_N`,
	"options-page__dialog": `options-page__dialog_Gjt1Q`,
	"options-page__list": `options-page__list_XXc6G`,
	"options-page__button-container": `options-page__button-container_uX8y2`
};
export default ___CSS_LOADER_EXPORT___;
