// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .sumsub-container_K8JBR{position:absolute;top:0;right:0;bottom:0;left:0;overflow-y:auto}html.app__layout--desktop .sumsub_ygBGR{min-height:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sumsub-container": `sumsub-container_K8JBR`,
	"sumsub": `sumsub_ygBGR`
};
export default ___CSS_LOADER_EXPORT___;
