// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.limit-status-card_o6CMj{display:flex;column-gap:16px;align-items:center;justify-content:space-between;padding:20px 16px;background-color:var(--Layer1);border-radius:5px}.limit-status-card__heading_R0Poq{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;margin-bottom:8px;color:var(--TextSecondary)}.limit-status-card__sub-heading_qZ8rJ{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;color:var(--TextDefault)}.limit-status-card__buttons_hTvzM{display:flex;flex-direction:column;row-gap:8px;min-width:143px;margin-top:16px}.limit-status-card__progress-content_oTv9d{position:relative;text-align:center}.limit-status-card__progress-content-top-text_VTnG3{font-size:12px;font-weight:400;line-height:12px;letter-spacing:normal;position:absolute;right:0;bottom:100%;left:0;color:var(--TextSecondary)}.limit-status-card__progress-content-main-text__LU_m{font-size:24px;font-weight:500;line-height:28px;letter-spacing:normal;padding:8px;color:var(--TextDefault)}.limit-status-card__progress-content-main-text--disabled_YJvBH{color:var(--TextSecondary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"limit-status-card": `limit-status-card_o6CMj`,
	"limit-status-card__heading": `limit-status-card__heading_R0Poq`,
	"limit-status-card__sub-heading": `limit-status-card__sub-heading_qZ8rJ`,
	"limit-status-card__buttons": `limit-status-card__buttons_hTvzM`,
	"limit-status-card__progress-content": `limit-status-card__progress-content_oTv9d`,
	"limit-status-card__progress-content-top-text": `limit-status-card__progress-content-top-text_VTnG3`,
	"limit-status-card__progress-content-main-text": `limit-status-card__progress-content-main-text__LU_m`,
	"limit-status-card__progress-content-main-text--disabled": `limit-status-card__progress-content-main-text--disabled_YJvBH`
};
export default ___CSS_LOADER_EXPORT___;
