// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.combi-bet-list-item--hover_TBpW9{cursor:pointer}.combi-bet-list-item__league_ZDI8K{font-weight:400;display:block;width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.combi-bet-list-item__league_ZDI8K,.combi-bet-list-item__status_siRis{font-size:12px;line-height:16px;letter-spacing:normal;color:var(--TextSecondary)}.combi-bet-list-item__status_siRis{font-weight:500}.combi-bet-list-item__status--pending_X4gUq{color:var(--AlertText)}.combi-bet-list-item__status--positive_RrGhv{color:var(--BrandDefault)}.combi-bet-list-item__status--negative_A4__B{color:var(--ErrorText)}.combi-bet-list-item__event-description_HoiMA{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;display:block;overflow:hidden;color:var(--TextDefault);text-overflow:ellipsis;white-space:nowrap}.combi-bet-list-item__banker_KXJBR{font-size:12px;font-weight:500;line-height:12px;letter-spacing:normal;display:inline-flex;align-items:center;justify-content:center;width:16px;height:16px;margin-left:4px;color:var(--Layer1);text-align:center;background-color:var(--TextDefault);border-radius:2px}.combi-bet-list-item__coefficient_FKdoP{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;display:block;color:var(--TextDefault)}.combi-bet-list-item__date_Ux9K1{font-size:12px;font-weight:400;line-height:16px;letter-spacing:normal;color:var(--TextSecondary)}.combi-bet-list-item__prediction_iG3qM{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px}.combi-bet-list-item__prediction-label_ElI0Q{color:var(--TextDefault)}.combi-bet-list-item__prediction-choice_Fx7ov{color:var(--BrandDefault);white-space:nowrap}.combi-bet-list-item__score_uLnHS{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;display:block;color:var(--TextDefault)}.combi-bet-list-item__score--positive_f2BMD>span:first-child,.combi-bet-list-item__score--positive_f2BMD>span:last-child{color:var(--BrandDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"combi-bet-list-item--hover": `combi-bet-list-item--hover_TBpW9`,
	"combi-bet-list-item__league": `combi-bet-list-item__league_ZDI8K`,
	"combi-bet-list-item__status": `combi-bet-list-item__status_siRis`,
	"combi-bet-list-item__status--pending": `combi-bet-list-item__status--pending_X4gUq`,
	"combi-bet-list-item__status--positive": `combi-bet-list-item__status--positive_RrGhv`,
	"combi-bet-list-item__status--negative": `combi-bet-list-item__status--negative_A4__B`,
	"combi-bet-list-item__event-description": `combi-bet-list-item__event-description_HoiMA`,
	"combi-bet-list-item__banker": `combi-bet-list-item__banker_KXJBR`,
	"combi-bet-list-item__coefficient": `combi-bet-list-item__coefficient_FKdoP`,
	"combi-bet-list-item__date": `combi-bet-list-item__date_Ux9K1`,
	"combi-bet-list-item__prediction": `combi-bet-list-item__prediction_iG3qM`,
	"combi-bet-list-item__prediction-label": `combi-bet-list-item__prediction-label_ElI0Q`,
	"combi-bet-list-item__prediction-choice": `combi-bet-list-item__prediction-choice_Fx7ov`,
	"combi-bet-list-item__score": `combi-bet-list-item__score_uLnHS`,
	"combi-bet-list-item__score--positive": `combi-bet-list-item__score--positive_f2BMD`
};
export default ___CSS_LOADER_EXPORT___;
