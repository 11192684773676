import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { VForm } from 'web/src/components/Form';
import { VLoaderDelayed } from 'web/src/components/Loader';
import usePasswordChangePage from './composables/usePasswordChangePage';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PasswordChangeRoutePage',
    setup (__props) {
        const { schema, uiSchema, isFormReady, passwordChangeForm, onSubmit } = usePasswordChangePage();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['password-change-wrapper'])
            }, [
                _unref(isFormReady) && _unref(schema) ? (_openBlock(), _createBlock(_unref(VForm), {
                    key: 1,
                    ref_key: "passwordChangeForm",
                    ref: passwordChangeForm,
                    schema: _unref(schema),
                    "ui-schema": _unref(uiSchema),
                    onSubmit: _unref(onSubmit)
                }, null, 8, [
                    "schema",
                    "ui-schema",
                    "onSubmit"
                ])) : (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                    key: 0
                }))
            ], 2)), [
                [
                    _directive_auto_id,
                    'PasswordChangeRoutePage'
                ]
            ]);
        };
    }
});
