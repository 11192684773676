// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.responsible-gambling-quiz-result_UPJ2u{grid-template-columns:40px 1fr;gap:16px;align-items:center;padding:12px;background-color:var(--Layer1);border-radius:5px;display:grid}.responsible-gambling-quiz-result__icon_ly91K{width:40px;height:40px;color:var(--TextDefault);background-color:var(--Layer2);border-radius:8px;display:flex;flex-shrink:0;align-items:center;justify-content:center}.responsible-gambling-quiz-result__title_HHfk5{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;color:var(--TextDefault)}.responsible-gambling-quiz-result__description_rjzFv{font-size:12px;font-weight:500;line-height:16px;letter-spacing:normal;margin-bottom:8px;color:var(--TextSecondary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"responsible-gambling-quiz-result": `responsible-gambling-quiz-result_UPJ2u`,
	"responsible-gambling-quiz-result__icon": `responsible-gambling-quiz-result__icon_ly91K`,
	"responsible-gambling-quiz-result__title": `responsible-gambling-quiz-result__title_HHfk5`,
	"responsible-gambling-quiz-result__description": `responsible-gambling-quiz-result__description_rjzFv`
};
export default ___CSS_LOADER_EXPORT___;
