// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rg-exclusion-info_WICsU{display:flex;flex-direction:column;width:100%}.rg-exclusion-info__image_knD0V{width:100%}.rg-exclusion-info__content_KxfH1{padding:16px;text-align:center;display:flex;flex-direction:column;width:100%}.rg-exclusion-info__title_tkrDg{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal;padding-bottom:16px;display:block}.rg-exclusion-info__description_Ja1xl{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;margin-bottom:34px;color:var(--TextPrimary);display:block}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rg-exclusion-info": `rg-exclusion-info_WICsU`,
	"rg-exclusion-info__image": `rg-exclusion-info__image_knD0V`,
	"rg-exclusion-info__content": `rg-exclusion-info__content_KxfH1`,
	"rg-exclusion-info__title": `rg-exclusion-info__title_tkrDg`,
	"rg-exclusion-info__description": `rg-exclusion-info__description_Ja1xl`
};
export default ___CSS_LOADER_EXPORT___;
