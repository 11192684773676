// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gambling-option_psYEU{display:flex;flex-direction:column;gap:16px;align-items:center;height:164px;padding:32px 16px 8px;background-color:var(--Button);border:none;border-radius:10px;outline:none;box-shadow:none;transition:all .1s ease-in-out}@media(hover:hover)and (pointer:fine){.gambling-option_psYEU:hover:not([disabled]){cursor:pointer;background-color:var(--Highlight)}.gambling-option_psYEU:active:hover{background-color:var(--Button);transform:scale(.99)}}.gambling-option__title_pFRHr{font-size:13px;font-weight:500;line-height:16px;letter-spacing:normal;color:var(--TextDefault);text-align:center}.gambling-option__icon_KYjW6{display:flex;flex-shrink:0;align-items:center;justify-content:center;width:56px;height:56px;color:var(--BrandText);border-radius:50%}.gambling-option__icon--test_iDWkZ{color:var(--BrandText);background-color:var(--Purple)}.gambling-option__icon--support_XFUkT{color:var(--BrandText);background-color:var(--Orange)}.gambling-option__icon--limits_F8AZK{color:var(--BrandText);background-color:var(--Yellow)}.gambling-option__icon--info_mMKKT{color:var(--BrandText);background-color:var(--Mint)}.gambling-option_psYEU:focus-visible{background-color:var(--Highlight);box-shadow:inset 0 0 0 1px #ffffff4d}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gambling-option": `gambling-option_psYEU`,
	"gambling-option__title": `gambling-option__title_pFRHr`,
	"gambling-option__icon": `gambling-option__icon_KYjW6`,
	"gambling-option__icon--test": `gambling-option__icon--test_iDWkZ`,
	"gambling-option__icon--support": `gambling-option__icon--support_XFUkT`,
	"gambling-option__icon--limits": `gambling-option__icon--limits_F8AZK`,
	"gambling-option__icon--info": `gambling-option__icon--info_mMKKT`
};
export default ___CSS_LOADER_EXPORT___;
