// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bonus-code-enter_etoPN{padding:16px}.bonus-code-enter__description_THTVG{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;margin:0 0 20px;color:var(--TextPrimary);text-align:center}html.app__layout--desktop .bonus-code-enter_etoPN{padding:16px 32px 32px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bonus-code-enter": `bonus-code-enter_etoPN`,
	"bonus-code-enter__description": `bonus-code-enter__description_THTVG`
};
export default ___CSS_LOADER_EXPORT___;
