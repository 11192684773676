// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time-in-game-info_vhXBe{display:flex;flex-direction:column;align-items:center;width:100%}.time-in-game-info__image_KzOrR{width:100%;max-width:100%}.time-in-game-info__content_I5r_E{display:flex;flex-direction:column;width:100%;padding:16px}html.app__layout--desktop .time-in-game-info__content_I5r_E{padding:16px}.time-in-game-info__title_IH98u{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal;margin-bottom:16px;text-align:center;display:block}.time-in-game-info__description_KVUl4{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;margin-bottom:34px;color:var(--TextPrimary);text-align:center;display:block}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"time-in-game-info": `time-in-game-info_vhXBe`,
	"time-in-game-info__image": `time-in-game-info__image_KzOrR`,
	"time-in-game-info__content": `time-in-game-info__content_I5r_E`,
	"time-in-game-info__title": `time-in-game-info__title_IH98u`,
	"time-in-game-info__description": `time-in-game-info__description_KVUl4`
};
export default ___CSS_LOADER_EXPORT___;
