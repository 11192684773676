// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time-in-game_N6L2J{display:flex;flex-direction:column;flex-grow:1;width:100%;padding:16px}html.app__layout--desktop .time-in-game_N6L2J{padding:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"time-in-game": `time-in-game_N6L2J`
};
export default ___CSS_LOADER_EXPORT___;
