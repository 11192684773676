import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 1
};
import { onBeforeMount } from 'vue';
import { SlottIconName } from '@leon-hub/icons';
import { VLoaderDelayed } from 'web/src/components/Loader';
import SHintBlock from 'web/src/components/HintBlock/SHintBlock/SHintBlock.vue';
import { TextSlotPlaceholder } from 'web/src/components/TextSlotPlaceholder';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { VForm } from 'web/src/components/Form';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import useResponsibleGamblingExclusionRoutePage from './composables/useResponsibleGamblingExclusionRoutePage';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingExclusionRoutePage',
    setup (__props) {
        const { selfExclusion, formattedFrom, formattedUntil, schema, uiSchema, isLoading, isButtonDisabled, onChange, beforeMount, showConfirmModal, handleSupportClick, onClickGoToGame } = useResponsibleGamblingExclusionRoutePage();
        onBeforeMount(beforeMount);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['self-exclusion'])
            }, [
                _unref(selfExclusion) ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _unref(selfExclusion).isSelfExcluded ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 0
                    }, [
                        (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            _createVNode(VHintBlock, {
                                "has-icon": false
                            }, {
                                title: _withCtx(()=>[
                                        _createTextVNode(_toDisplayString(_ctx.$t('WEB2_SELF_EXCLUSION_SUCCESS_HINT_TITLE')), 1)
                                    ]),
                                default: _withCtx(()=>[
                                        _createTextVNode(_toDisplayString(_ctx.$t('WEB2_SELF_EXCLUSION_SUCCESS_HINT_DESC')), 1)
                                    ]),
                                _: 1
                            }),
                            _createVNode(VHintBlock, {
                                "has-icon": false
                            }, {
                                title: _withCtx(()=>[
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['self-exclusion__hint-period-label'])
                                        }, _toDisplayString(_ctx.$t('WEB2_SELF_EXCLUSION_PERIOD_LABEL')), 3)
                                    ]),
                                default: _withCtx(()=>[
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['self-exclusion__hint-period'])
                                        }, [
                                            _createElementVNode("div", {
                                                class: _normalizeClass(_ctx.$style['self-exclusion__hint-period-item'])
                                            }, [
                                                _createElementVNode("div", {
                                                    class: _normalizeClass(_ctx.$style['self-exclusion__hint-period-title'])
                                                }, _toDisplayString(_ctx.$t('WEB2_SELF_EXCLUSION_PERIOD_LABEL_FROM')), 3),
                                                _createElementVNode("div", {
                                                    class: _normalizeClass(_ctx.$style['self-exclusion__hint-period-value'])
                                                }, _toDisplayString(_unref(formattedFrom)), 3)
                                            ], 2),
                                            _createElementVNode("div", {
                                                class: _normalizeClass(_ctx.$style['self-exclusion__hint-period-item'])
                                            }, [
                                                _createElementVNode("div", {
                                                    class: _normalizeClass(_ctx.$style['self-exclusion__hint-period-title'])
                                                }, _toDisplayString(_ctx.$t('WEB2_SELF_EXCLUSION_PERIOD_LABEL_UNTIL')), 3),
                                                _createElementVNode("div", {
                                                    class: _normalizeClass(_ctx.$style['self-exclusion__hint-period-value'])
                                                }, _toDisplayString(_unref(formattedUntil)), 3)
                                            ], 2)
                                        ], 2)
                                    ]),
                                _: 1
                            }),
                            _createVNode(VButton, {
                                "full-width": "",
                                kind: _unref(ButtonKind).SECONDARY,
                                label: _ctx.$t('WEB2_HELP'),
                                height: _unref(ButtonHeight).MEDIUM,
                                onClick: _unref(handleSupportClick)
                            }, null, 8, [
                                "kind",
                                "label",
                                "height",
                                "onClick"
                            ])
                        ], 64))
                    ], 64)) : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        (_openBlock(), _createBlock(VHintBlock, {
                            key: 1,
                            "has-icon": false
                        }, {
                            title: _withCtx(()=>[
                                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('WEB2_SELF_EXCLUSION_HINT_TITLE')), 1)
                                ]),
                            default: _withCtx(()=>[
                                    _createVNode(_unref(TextSlotPlaceholder), {
                                        text: _ctx.$t('WEB2_SELF_EXCLUSION_HINT_DESCR')
                                    }, null, 8, [
                                        "text"
                                    ])
                                ]),
                            _: 1
                        })),
                        _unref(schema) ? (_openBlock(), _createBlock(_unref(VForm), {
                            key: 2,
                            ref: "form",
                            schema: _unref(schema),
                            "ui-schema": _unref(uiSchema),
                            "is-pending": _unref(isLoading),
                            onChange: _unref(onChange)
                        }, null, 8, [
                            "schema",
                            "ui-schema",
                            "is-pending",
                            "onChange"
                        ])) : _createCommentVNode("", true),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['self-exclusion__buttons'])
                        }, [
                            _createVNode(VButton, {
                                disabled: _unref(isButtonDisabled),
                                label: _ctx.$t('WEB2_SELF_EXCLUSION_ENABLE_BUTTON'),
                                kind: _unref(ButtonKind).PRIMARY,
                                "full-width": "",
                                onClick: _unref(showConfirmModal)
                            }, null, 8, [
                                "disabled",
                                "label",
                                "kind",
                                "onClick"
                            ]),
                            (_openBlock(), _createBlock(VButton, {
                                key: 1,
                                label: _ctx.$t('WEB2_SELF_EXCLUSION_BACK_TO_GAME__BUTTON'),
                                kind: _unref(ButtonKind).BASE,
                                "full-width": "",
                                onClick: _unref(onClickGoToGame)
                            }, null, 8, [
                                "label",
                                "kind",
                                "onClick"
                            ]))
                        ], 2)
                    ]))
                ], 64)) : (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                    key: 0
                }))
            ], 2)), [
                [
                    _directive_auto_id,
                    'ResponsibleGamblingExclusionRoutePage'
                ]
            ]);
        };
    }
});
