import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "onClick"
];
const _hoisted_2 = [
    "src"
];
import { useVThemeSwitcher } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VThemeSwitcher',
    emits: [
        "change"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const { themeList } = useVThemeSwitcher();
        function emitChange(theme) {
            emit('change', theme);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['theme-switcher'])
            }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(themeList), (theme, index)=>(_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: _normalizeClass({
                            [_ctx.$style['theme-switcher__button']]: true,
                            [_ctx.$style['theme-switcher__button--active']]: theme.isActive
                        }),
                        onClick: ($event)=>emitChange(theme.theme)
                    }, [
                        _createElementVNode("img", {
                            class: _normalizeClass(_ctx.$style['theme-switcher__button-image']),
                            src: require(`web/src/assets/images/theme-select-circle-${theme.theme.toLowerCase()}.svg`),
                            alt: ""
                        }, null, 10, _hoisted_2),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['theme-switcher__button-label'])
                        }, _toDisplayString(theme.name), 3)
                    ], 10, _hoisted_1))), 128))
            ], 2)), [
                [
                    _directive_auto_id,
                    'VThemeSwitcher'
                ]
            ]);
        };
    }
});
