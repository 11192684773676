// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .self-exclusion_tWVKf{display:flex;flex-flow:column wrap;gap:12px;padding:16px}html.app__layout--desktop .self-exclusion__buttons_qw11r{display:flex;flex-flow:column wrap;gap:16px;padding:8px 0}html.app__layout--desktop .self-exclusion__hint-period_Uq_sn{display:flex;gap:16px;padding:8px 0}html.app__layout--desktop .self-exclusion__hint-period-item_PsLZP{width:50%}html.app__layout--desktop .self-exclusion__hint-period-label_E_Uhx{font-size:16px;font-weight:500;line-height:20px;letter-spacing:.25px;padding:4px 0}html.app__layout--desktop .self-exclusion__hint-period-title_pr344{font-size:13px;font-weight:400;line-height:16px;letter-spacing:normal;padding-bottom:4px;color:var(--TextSecondary)}html.app__layout--desktop .self-exclusion__hint-period-value_XSyPk{font-size:16px;font-weight:500;line-height:20px;letter-spacing:.25px;color:var(--TextDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"self-exclusion": `self-exclusion_tWVKf`,
	"self-exclusion__buttons": `self-exclusion__buttons_qw11r`,
	"self-exclusion__hint-period": `self-exclusion__hint-period_Uq_sn`,
	"self-exclusion__hint-period-item": `self-exclusion__hint-period-item_PsLZP`,
	"self-exclusion__hint-period-label": `self-exclusion__hint-period-label_E_Uhx`,
	"self-exclusion__hint-period-title": `self-exclusion__hint-period-title_pr344`,
	"self-exclusion__hint-period-value": `self-exclusion__hint-period-value_XSyPk`
};
export default ___CSS_LOADER_EXPORT___;
