// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history-details__list_864Ke{margin:0}.history-details__copy-icon_nXljW{flex-shrink:0;margin-right:-4px;margin-left:8px;fill:var(--TextSecondary)}.history-details__support-block_L2tCP,.history-details__withdrawal-button_HhjyC{margin-top:8px}.history-details__hint_AGHYz{margin-bottom:16px}html.app__layout--desktop .history-details__cancel-withdrawal_dM8d8{margin:-2px -4px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"history-details__list": `history-details__list_864Ke`,
	"history-details__copy-icon": `history-details__copy-icon_nXljW`,
	"history-details__support-block": `history-details__support-block_L2tCP`,
	"history-details__withdrawal-button": `history-details__withdrawal-button_HhjyC`,
	"history-details__hint": `history-details__hint_AGHYz`,
	"history-details__cancel-withdrawal": `history-details__cancel-withdrawal_dM8d8`
};
export default ___CSS_LOADER_EXPORT___;
