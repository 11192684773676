import { ref } from 'vue';
// eslint-disable-next-line max-len
export default function useCustomerHistoryDetailsCardStatistics() {
    const isOpen = ref(false);
    function toggleStatistics() {
        isOpen.value = !isOpen.value;
    }
    return {
        isOpen,
        toggleStatistics
    };
}
