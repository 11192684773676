// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vv-start-screen_Vmn7H{padding:65px 16px 20px}.vv-start-screen__text_PwMgv{padding-bottom:20px;text-align:center}.vv-start-screen__image_u0C7r{display:block;margin:0 auto 50px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vv-start-screen": `vv-start-screen_Vmn7H`,
	"vv-start-screen__text": `vv-start-screen__text_PwMgv`,
	"vv-start-screen__image": `vv-start-screen__image_u0C7r`
};
export default ___CSS_LOADER_EXPORT___;
