// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.responsible-gambling-top-bar__suffix_f1hwX{position:absolute;right:0;display:flex;padding:0}.responsible-gambling-top-bar__button_lnd_u{color:var(--TextSecondary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"responsible-gambling-top-bar__suffix": `responsible-gambling-top-bar__suffix_f1hwX`,
	"responsible-gambling-top-bar__button": `responsible-gambling-top-bar__button_lnd_u`
};
export default ___CSS_LOADER_EXPORT___;
