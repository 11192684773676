// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timer-step_FXMbX{display:flex;flex-direction:column;flex-grow:1}.timer-step-title_aG_05{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;color:var(--TextPrimary);margin-bottom:24px;text-align:center}.timer-step-container_fQ884{flex-grow:1}.timer-step-button__set_wII3V{margin-top:40px}.timer-step-button__cancel_JsHJe{margin-top:20px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timer-step": `timer-step_FXMbX`,
	"timer-step-title": `timer-step-title_aG_05`,
	"timer-step-container": `timer-step-container_fQ884`,
	"timer-step-button__set": `timer-step-button__set_wII3V`,
	"timer-step-button__cancel": `timer-step-button__cancel_JsHJe`
};
export default ___CSS_LOADER_EXPORT___;
