// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-info_T7OTU{display:flex;align-items:center}.profile-info__text-container_Nt8_O{display:flex;flex:1;flex-direction:column;margin-left:12px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profile-info": `profile-info_T7OTU`,
	"profile-info__text-container": `profile-info__text-container_Nt8_O`
};
export default ___CSS_LOADER_EXPORT___;
