// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.theme-switcher_xHxSf{display:grid;grid-template-columns:repeat(3,1fr);grid-column-gap:32px;width:max-content;margin:0 auto}.theme-switcher__button_qFBO9{display:flex;flex-direction:column;align-items:center}@media(hover:hover)and (pointer:fine){.theme-switcher__button_qFBO9:hover{cursor:pointer}}.theme-switcher__button-image_x4TDF{width:40px;height:40px;margin-bottom:8px;overflow:hidden;border:2px solid var(--Highlight);border-radius:50%}.theme-switcher__button-label_md68V{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;color:var(--TextSecondary);text-align:center}.theme-switcher__button--active_jByll .theme-switcher__button-label_md68V{color:var(--TextDefault)}.theme-switcher__button--active_jByll .theme-switcher__button-image_x4TDF{border-color:var(--BrandDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"theme-switcher": `theme-switcher_xHxSf`,
	"theme-switcher__button": `theme-switcher__button_qFBO9`,
	"theme-switcher__button-image": `theme-switcher__button-image_x4TDF`,
	"theme-switcher__button-label": `theme-switcher__button-label_md68V`,
	"theme-switcher__button--active": `theme-switcher__button--active_jByll`
};
export default ___CSS_LOADER_EXPORT___;
