// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.responsible-gambling-timer_DbQy4{display:flex;flex-direction:column;gap:24px}.responsible-gambling-timer__header_BLYfb{display:flex;gap:16px;justify-content:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"responsible-gambling-timer": `responsible-gambling-timer_DbQy4`,
	"responsible-gambling-timer__header": `responsible-gambling-timer__header_BLYfb`
};
export default ___CSS_LOADER_EXPORT___;
