// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subscriptions-list__page_y1a1C{padding:0}.subscriptions-list__container_Zm2eN{padding:16px 8px}.subscriptions-list__content_RXpA_{padding:0 4px}.subscriptions-list__text_bUuwa{font-size:13px;font-weight:400;line-height:16px;letter-spacing:normal;margin-bottom:8px;color:var(--TextSecondary)}.subscriptions-list__text--disabled_QdjMD{opacity:.3}html.app__layout--desktop .subscriptions-list_GlM68{padding:16px 24px;margin:0}html.app__layout--desktop .subscriptions-list_GlM68:last-child{margin-bottom:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subscriptions-list__page": `subscriptions-list__page_y1a1C`,
	"subscriptions-list__container": `subscriptions-list__container_Zm2eN`,
	"subscriptions-list__content": `subscriptions-list__content_RXpA_`,
	"subscriptions-list__text": `subscriptions-list__text_bUuwa`,
	"subscriptions-list__text--disabled": `subscriptions-list__text--disabled_QdjMD`,
	"subscriptions-list": `subscriptions-list_GlM68`
};
export default ___CSS_LOADER_EXPORT___;
