// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history-details__list_Wa4ov{margin:0}.history-details__copy-icon_OiCZo{flex-shrink:0;margin-right:-4px;margin-left:8px;fill:var(--TextSecondary)}.history-details__support-block_KcCye,.history-details__withdrawal-button_mEG9x{margin-top:8px}.history-details__hint_Iiq2i{margin-bottom:16px}html.app__layout--desktop .history-details__cancel-withdrawal_d7rCg{margin:-2px -4px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"history-details__list": `history-details__list_Wa4ov`,
	"history-details__copy-icon": `history-details__copy-icon_OiCZo`,
	"history-details__support-block": `history-details__support-block_KcCye`,
	"history-details__withdrawal-button": `history-details__withdrawal-button_mEG9x`,
	"history-details__hint": `history-details__hint_Iiq2i`,
	"history-details__cancel-withdrawal": `history-details__cancel-withdrawal_d7rCg`
};
export default ___CSS_LOADER_EXPORT___;
