// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-verification_dOhyf{padding:16px}.video-verification__content_paWBd{padding-bottom:0}.video-verification__center_RQsgS{padding-bottom:60px;margin:auto}.video-verification__hint_WODl_{margin-top:20px;text-align:left}.video-verification__hint-text_IcioO{padding-top:6px}.video-verification__change-time_PInOQ{display:block;-webkit-text-decoration:underline;text-decoration:underline;cursor:pointer}.video-verification_dOhyf :deep(.jumbotron__text_jUBaY){padding-bottom:0}.vv-time_qjqY4{font-size:18px;font-weight:500;line-height:24px;letter-spacing:normal;color:var(--TextDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"video-verification": `video-verification_dOhyf`,
	"video-verification__content": `video-verification__content_paWBd`,
	"video-verification__center": `video-verification__center_RQsgS`,
	"video-verification__hint": `video-verification__hint_WODl_`,
	"video-verification__hint-text": `video-verification__hint-text_IcioO`,
	"video-verification__change-time": `video-verification__change-time_PInOQ`,
	"jumbotron__text": `jumbotron__text_jUBaY`,
	"vv-time": `vv-time_qjqY4`
};
export default ___CSS_LOADER_EXPORT___;
