// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history-details-route-component_bf6W1{padding:0 8px 64px}.history-details-route-component--desktop_rJddH{padding:0 24px 32px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"history-details-route-component": `history-details-route-component_bf6W1`,
	"history-details-route-component--desktop": `history-details-route-component--desktop_rJddH`
};
export default ___CSS_LOADER_EXPORT___;
