// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .responsible-gambling-limits_liRXz{display:flex;flex-direction:column;width:100%;padding:16px 16px 24px}html.app__layout--desktop .responsible-gambling-limits__item_G1OMu{padding:20px 16px;border-radius:20px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"responsible-gambling-limits": `responsible-gambling-limits_liRXz`,
	"responsible-gambling-limits__item": `responsible-gambling-limits__item_G1OMu`
};
export default ___CSS_LOADER_EXPORT___;
