// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.responsible-gambling-timer-time_le2cL{font-size:54px;font-weight:500;line-height:54px;color:var(--TextSecondary)}.responsible-gambling-timer-time__label_hASXG{font-size:20px}.responsible-gambling-timer-time--active__tXot{color:var(--TextDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"responsible-gambling-timer-time": `responsible-gambling-timer-time_le2cL`,
	"responsible-gambling-timer-time__label": `responsible-gambling-timer-time__label_hASXG`,
	"responsible-gambling-timer-time--active": `responsible-gambling-timer-time--active__tXot`
};
export default ___CSS_LOADER_EXPORT___;
