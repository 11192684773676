// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bet-details__statistics-wrapper_NCxcQ{margin-bottom:16px}.bet-details__scoreboard_LYskC{margin:12px 0}.bet-details__share_iuUvY{margin:12px 0 0}.bet-details__description_Snr6R{margin-bottom:8px}.bet-details__bet-list-heading_FQ2DE{font-size:13px;font-weight:500;line-height:16px;letter-spacing:normal;display:block;padding:16px 8px 12px;color:var(--TextSecondary)}html.app__layout--desktop .bet-details__bet-list-heading_FQ2DE{text-align:center}.bet-details__support_gJdo3{margin-top:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bet-details__statistics-wrapper": `bet-details__statistics-wrapper_NCxcQ`,
	"bet-details__scoreboard": `bet-details__scoreboard_LYskC`,
	"bet-details__share": `bet-details__share_iuUvY`,
	"bet-details__description": `bet-details__description_Snr6R`,
	"bet-details__bet-list-heading": `bet-details__bet-list-heading_FQ2DE`,
	"bet-details__support": `bet-details__support_gJdo3`
};
export default ___CSS_LOADER_EXPORT___;
