// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.initial-step_Nnf_E{display:inline-flex;flex-direction:column;gap:16px}.initial-step-notification-description_wOX3y{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;display:block;margin-top:4px;color:var(--TextPrimary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"initial-step": `initial-step_Nnf_E`,
	"initial-step-notification-description": `initial-step-notification-description_wOX3y`
};
export default ___CSS_LOADER_EXPORT___;
