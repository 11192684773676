import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withCtx as _withCtx, createSlots as _createSlots, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "profile-bonuses-top-bar__suffix"
};
const _hoisted_2 = {
    key: 0,
    class: "profile-bonuses-top-bar__content"
};
import { IconName, IconSize } from '@leon-hub/icons';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import DefaultTopBarRouteComponent from 'web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import VSwitcher from 'web/src/components/Switcher/VSwitcher/VSwitcher.vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import { useProfileBonusesTopBarRouteComponent } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ProfileBonusesTopBarRouteComponent',
    props: {
        title: {
            default: ''
        }
    },
    setup (__props) {
        const props = __props;
        const { onboardingOpened, topBarTitle, hasBonusesOnboarding, complexData, handleOnboardingButtonClick, handleComplexDataButtonClick, handleBackTitle, handleCloseButtonClick, switcherOptions, activeTab, switchTab } = useProfileBonusesTopBarRouteComponent(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(DefaultTopBarRouteComponent, {
                class: "profile-bonuses-top-bar",
                title: _unref(topBarTitle),
                "only-emit-events": "",
                onBack: _unref(handleBackTitle)
            }, _createSlots({
                title: _withCtx(()=>[
                        _unref(complexData) ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createVNode(VDynamicContent, {
                                content: _unref(topBarTitle),
                                class: "profile-bonuses-top-bar__title"
                            }, null, 8, [
                                "content"
                            ]),
                            (_openBlock(), _createBlock(VSwitcher, {
                                key: 0,
                                options: _unref(switcherOptions),
                                "active-id": _unref(activeTab),
                                class: "profile-bonuses-top-bar__switcher",
                                onChange: _unref(switchTab)
                            }, null, 8, [
                                "options",
                                "active-id",
                                "onChange"
                            ]))
                        ]))
                    ]),
                _: 2
            }, [
                _unref(onboardingOpened) ? void 0 : {
                    name: "prefix",
                    fn: _withCtx(()=>[
                            _unref(hasBonusesOnboarding) ? (_openBlock(), _createBlock(VButton, {
                                key: 0,
                                "icon-name": _unref(IconName).INFO_OUTLINE,
                                "icon-size": _unref(IconSize).SIZE_24,
                                kind: _unref(ButtonKind).TRANSPARENT,
                                class: "profile-bonuses-top-bar__button",
                                onClick: _unref(handleOnboardingButtonClick)
                            }, null, 8, [
                                "icon-name",
                                "icon-size",
                                "kind",
                                "onClick"
                            ])) : _unref(complexData) ? (_openBlock(), _createBlock(VButton, {
                                key: 1,
                                "icon-name": _unref(IconName).ARROW_LEFT,
                                "icon-size": _unref(IconSize).SIZE_24,
                                kind: _unref(ButtonKind).TRANSPARENT,
                                class: "profile-bonuses-top-bar__button",
                                onClick: _unref(handleComplexDataButtonClick)
                            }, null, 8, [
                                "icon-name",
                                "icon-size",
                                "kind",
                                "onClick"
                            ])) : _createCommentVNode("", true)
                        ]),
                    key: "0"
                },
                {
                    name: "suffix",
                    fn: _withCtx(()=>[
                            _createElementVNode("div", _hoisted_1, [
                                (_unref(hasBonusesOnboarding), _createCommentVNode("", true)),
                                (_openBlock(), _createBlock(VButton, {
                                    key: 1,
                                    "icon-name": _unref(IconName).CROSS,
                                    "icon-size": _unref(IconSize).SIZE_24,
                                    kind: _unref(ButtonKind).TRANSPARENT,
                                    class: "profile-bonuses-top-bar__button",
                                    onClick: _unref(handleCloseButtonClick)
                                }, null, 8, [
                                    "icon-name",
                                    "icon-size",
                                    "kind",
                                    "onClick"
                                ]))
                            ])
                        ]),
                    key: "1"
                }
            ]), 1032, [
                "title",
                "onBack"
            ])), [
                [
                    _directive_auto_id,
                    'ProfileBonusesTopBarRouteComponent'
                ]
            ]);
        };
    }
});
