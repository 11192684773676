// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.responsible-gambling-quiz-final-description_uSI2h{gap:24px;padding:24px 16px;background-color:var(--Layer1);border-radius:5px;display:flex;flex-direction:column}.responsible-gambling-quiz-final-description__top_v2OLM{display:grid;grid-template-columns:56px auto;gap:16px;align-items:center}.responsible-gambling-quiz-final-description__icon_V9K1U{color:var(--BrandText);background-color:var(--BrandDefault);display:flex;align-items:center;justify-content:center;width:56px;height:56px;border-radius:50%}.responsible-gambling-quiz-final-description__icon--moderately_EIwN9{background-color:var(--Yellow)}.responsible-gambling-quiz-final-description__icon--acceptable_Jjwvj{background-color:var(--Mint)}.responsible-gambling-quiz-final-description__icon--critical__RlZL{background-color:var(--ErrorDefault)}.responsible-gambling-quiz-final-description__title_u0Rzr{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal;color:var(--TextDefault)}.responsible-gambling-quiz-final-description__description_irSUB{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;color:var(--TextPrimary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"responsible-gambling-quiz-final-description": `responsible-gambling-quiz-final-description_uSI2h`,
	"responsible-gambling-quiz-final-description__top": `responsible-gambling-quiz-final-description__top_v2OLM`,
	"responsible-gambling-quiz-final-description__icon": `responsible-gambling-quiz-final-description__icon_V9K1U`,
	"responsible-gambling-quiz-final-description__icon--moderately": `responsible-gambling-quiz-final-description__icon--moderately_EIwN9`,
	"responsible-gambling-quiz-final-description__icon--acceptable": `responsible-gambling-quiz-final-description__icon--acceptable_Jjwvj`,
	"responsible-gambling-quiz-final-description__icon--critical": `responsible-gambling-quiz-final-description__icon--critical__RlZL`,
	"responsible-gambling-quiz-final-description__title": `responsible-gambling-quiz-final-description__title_u0Rzr`,
	"responsible-gambling-quiz-final-description__description": `responsible-gambling-quiz-final-description__description_irSUB`
};
export default ___CSS_LOADER_EXPORT___;
