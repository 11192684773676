// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-profile-sidebar_xJQy3{display:flex;flex-direction:column;max-height:calc(100vh - 176px);overflow:auto}.user-profile-sidebar__menu_YCYH4{padding:0 16px 20px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user-profile-sidebar": `user-profile-sidebar_xJQy3`,
	"user-profile-sidebar__menu": `user-profile-sidebar__menu_YCYH4`
};
export default ___CSS_LOADER_EXPORT___;
