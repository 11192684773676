import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { IconName } from '@leon-hub/icons';
import { AccountHistoryStatus, BetType } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config-names';
import { usePushNotificationsConfigSyncState } from 'web/src/modules/push-notifications/composables';
import { usePushNotificationSettingsStore } from 'web/src/modules/push-notifications/submodules/push-settings/store';
import { useUserStore } from 'web/src/modules/user/store';
import { useSnackbarsStore } from 'web/src/modules/snackbars/store';
import { usePushNotificationsStore } from 'web/src/modules/push-notifications/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useSlipShare } from 'web/src/modules/slip/submodules/slip-share/composables';
import { useAccumulatorBoostStore } from 'web/src/modules/slip/submodules/accumulator-boost/store';
// eslint-disable-next-line max-len
export default function useCustomerHistoryDetailsBet(props) {
    const { $translate } = useI18n();
    const router = useRouter();
    const userStore = useUserStore();
    const snackbarsStore = useSnackbarsStore();
    const pushNotificationsStore = usePushNotificationsStore();
    const pushNotificationSettingsStore = usePushNotificationSettingsStore();
    const consents = toRef(userStore, 'consents');
    const isAllowed = toRef(pushNotificationsStore, 'isAllowed');
    const isSupported = toRef(pushNotificationsStore, 'isSupported');
    const isPushOnBetsEnabled = toRef(pushNotificationsStore, 'isPushOnBetsEnabled');
    const isBetOutcomePushEnabled = toRef(pushNotificationSettingsStore, 'isBetOutcomePushEnabled');
    const isLive = computed(()=>props.transaction?.status === AccountHistoryStatus.BET_WAITING);
    const isEventUrl = computed(()=>!!props.transaction?.eventUrl);
    const isPushSupported = computed(()=>isSupported.value && isPushOnBetsEnabled.value);
    const isAllPushesEnabled = computed(()=>isAllowed.value && !!consents.value.pushNotification);
    const isPushEnabled = computed(()=>isAllPushesEnabled.value && isBetOutcomePushEnabled.value);
    const isWinBoost = toRef(useAccumulatorBoostStore(), 'isWinBoost');
    // TODO better have this logic on server or unify design
    const headingText = computed(()=>{
        const { combiStatusText, betType, betItems, betSystemM } = props.transaction;
        if (!combiStatusText) return '';
        const isBanker = !!betSystemM && betItems.length - betSystemM > 0;
        switch(betType){
            case BetType.EXPRESS:
                "1";
                return `${$translate('WEB2_BET_DETAILS_BET').value}-${combiStatusText.toLocaleLowerCase()}`;
            case BetType.SYSTEM:
                "1";
                if (isBanker) return `${combiStatusText}`;
                return `${$translate('WEB2_BET_DETAILS_BET').value} ${combiStatusText.toLocaleLowerCase()}:`;
            default:
                return '';
        }
    });
    const matchResult = computed(()=>{
        const result = props.transaction?.matchResult;
        if (!result) return null;
        const { redCards, yellowCards, yellowRedCards, cornersScore } = result;
        if (redCards && yellowCards && yellowRedCards && cornersScore) return result;
        return null;
    });
    function openPushOnboarding() {
        router.push({
            name: RouteName.PUSH_NOTIFICATIONS_BETS_ONBOARDING
        });
    }
    async function onPushSwitch(event) {
        event.preventDefault();
        event.stopPropagation();
        if (isAllPushesEnabled.value && (isBetOutcomePushEnabled.value || pushNotificationSettingsStore.wasSaved())) {
            await pushNotificationSettingsStore.toggleBetOutcomePush();
            return;
        }
        openPushOnboarding();
    }
    async function beforeMount() {
        await Promise.all([
            usePushNotificationsConfigSyncState(),
            pushNotificationSettingsStore.fetchSettings(),
            userStore.getNotificationConsents()
        ]);
        if (isPushSupported.value && !isPushEnabled.value && router.isOpenedByPush()) {
            snackbarsStore.show({
                id: 'betPush',
                title: $translate('WEB2_PUSH_BETS_SNACKBAR_TTL').value,
                text: $translate('WEB2_PUSH_BETS_SNACKBAR_MSG').value,
                iconName: IconName.BELL_ON,
                requiresAuth: true,
                accept: {
                    action: ()=>{
                        openPushOnboarding();
                        return Promise.resolve();
                    },
                    label: $translate('WEB2_PUSH_BETS_SNACKBAR_BTN').value
                },
                cancel: {
                    label: '&nbsp;'
                }
            });
            router.removePushQuery();
        }
    }
    const { isCustomerBetShareEnabled, showBetShareDialog, showAmountSwitcher } = useSlipShare();
    const shareBet = ()=>{
        const { objectId, accountType, status } = props.transaction;
        const betIsCalculated = status === AccountHistoryStatus.BET_CALCULATED || status === AccountHistoryStatus.BET_CALCULATED_CASHOUT;
        if (!betIsCalculated) showAmountSwitcher();
        showBetShareDialog({
            betId: Number(objectId),
            accountType,
            showAmount: true
        });
    };
    return {
        isLive,
        matchResult,
        isEventUrl,
        headingText,
        isPushSupported,
        isPushEnabled,
        isWinBoost,
        shareEnabled: isCustomerBetShareEnabled,
        onPushSwitch,
        beforeMount,
        shareBet
    };
}
