// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .responsible-gambling-test__image_zpPId{display:flex;justify-content:center;margin-bottom:0}html.app__layout--desktop .responsible-gambling-test__info-description_OaIvT{padding:0 32px;margin-bottom:32px;text-align:center}html.app__layout--desktop .responsible-gambling-test__button-container_nUQXC{padding:0 32px 32px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"responsible-gambling-test__image": `responsible-gambling-test__image_zpPId`,
	"responsible-gambling-test__info-description": `responsible-gambling-test__info-description_OaIvT`,
	"responsible-gambling-test__button-container": `responsible-gambling-test__button-container_nUQXC`
};
export default ___CSS_LOADER_EXPORT___;
