// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .user-settings_Os0kx{display:block;padding:16px 8px;padding:16px 24px}html.app__layout--desktop .user-settings_block_pX6tb{width:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user-settings": `user-settings_Os0kx`,
	"user-settings_block": `user-settings_block_pX6tb`
};
export default ___CSS_LOADER_EXPORT___;
