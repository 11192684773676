// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.responsible-gambling-quiz-progress__top-label_aukrY{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px;margin-bottom:8px;color:var(--TextSecondary)}.responsible-gambling-quiz-progress__bottom-label_fsmc5{font-size:12px;font-weight:400;line-height:16px;letter-spacing:normal;margin-top:2px;color:var(--Green);text-align:right}.responsible-gambling-quiz-progress__bottom-label--moderately_ZloQV{color:var(--Yellow)}.responsible-gambling-quiz-progress__bottom-label--acceptable_NaDFk{color:var(--Mint)}.responsible-gambling-quiz-progress__bottom-label--critical_fLlti{color:var(--ErrorDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"responsible-gambling-quiz-progress__top-label": `responsible-gambling-quiz-progress__top-label_aukrY`,
	"responsible-gambling-quiz-progress__bottom-label": `responsible-gambling-quiz-progress__bottom-label_fsmc5`,
	"responsible-gambling-quiz-progress__bottom-label--moderately": `responsible-gambling-quiz-progress__bottom-label--moderately_ZloQV`,
	"responsible-gambling-quiz-progress__bottom-label--acceptable": `responsible-gambling-quiz-progress__bottom-label--acceptable_NaDFk`,
	"responsible-gambling-quiz-progress__bottom-label--critical": `responsible-gambling-quiz-progress__bottom-label--critical_fLlti`
};
export default ___CSS_LOADER_EXPORT___;
