import { computed, getCurrentInstance, onBeforeMount, onMounted, ref, toRef } from 'vue';
import RouteName from '@leon-hub/routing-config-names';
import { getNavigationItemRouteLocation } from '@leon-hub/navigation-config';
import { useUserNavigationStore } from 'web/src/modules/user/submodules/navigation/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { convertNavigationToSidebarMenu } from 'web/src/components/SidebarMenu/utils';
import { useRouterStore, useSiteConfigStore, useStorageManagerStore } from 'web/src/modules/core/store';
import useOnboardingStore from 'web/src/modules/onboarding/store/useOnboardingStore';
import { MenuLevel } from 'web/src/components/SidebarMenu/types';
import { useUserStore } from 'web/src/modules/user/store';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { DialogAction, DialogPriority, PresetName } from 'web/src/modules/dialogs/enums';
import { useProfileBonusesStore } from 'web/src/modules/profile/submodules/bonuses/store';
import getOptionalString from 'web/src/utils/getOptionalString';
import useReferralProgramStore from 'web/src/modules/referral-program/store/useReferralProgramStore';
import { useCustomerNotificationsUnreadCounter } from 'web/src/modules/customer-notifications/composables';
import useBonusLoyaltyStore from 'web/src/modules/bonuses/store/useBonusLoyaltyStore';
import { usePinCodeStore } from 'web/src/modules/pin-code/store';
import { useCashbackStore } from 'web/src/modules/promotions/store';
const locationMapper = (navItem)=>{
    if (navItem?.props?.isLeonShop) return null;
    return getNavigationItemRouteLocation(navItem, 'useProfileSidebar');
};
const activeMapper = (navItem)=>!!navItem.isActive;
const sidebarMapper = {
    location: locationMapper,
    active: activeMapper
};
const logoutId = 'logout';
export function useProfileSidebar() {
    const { $translate } = useI18n();
    const { showDialog, closeDialog } = useDialogs();
    const userNavigationStore = useUserNavigationStore();
    const routerStore = useRouterStore();
    const onboardingStore = useOnboardingStore();
    const siteConfigStore = useSiteConfigStore();
    const storageManager = useStorageManagerStore();
    const userStore = useUserStore();
    const profileBonusesStore = useProfileBonusesStore();
    const referralProgramStore = useReferralProgramStore();
    const bonusLoyaltyStore = useBonusLoyaltyStore();
    const profileSidebarNavigation = toRef(userNavigationStore, 'profileSidebarNavigation');
    const currentRouteName = toRef(routerStore, 'currentRouteName');
    const egsBonuses = toRef(profileBonusesStore, 'egsBonuses');
    const sportBonuses = toRef(profileBonusesStore, 'sportBonuses');
    const referralProgramAvailability = toRef(referralProgramStore, 'availability');
    const { unreadCounter } = useCustomerNotificationsUnreadCounter();
    toRef(bonusLoyaltyStore, 'isParticipating');
    const isCashbackParticipatingNotConfirmed = toRef(useCashbackStore(), 'isCashbackParticipatingNotConfirmed');
    const isClearCachePressed = ref(false);
    let clearCacheModalId = null;
    onBeforeMount(async ()=>{
        if (siteConfigStore.isProfileReferralProgramEnabled) await referralProgramStore.getAvailability();
    });
    onMounted(async ()=>{
        await onboardingStore.getOnboarding({});
        await bonusLoyaltyStore.fetchBonusLoyalty();
        if (siteConfigStore.bonusesEnable) await profileBonusesStore.fetchBonuses();
    });
    function l1ActiveLevel(item) {
        const routeName = String(currentRouteName.value);
        const { children } = item;
        let isChild = false;
        if (children) isChild = children.map((i)=>i.routeName).includes(routeName);
        if (!!item?.props?.isCacheCleaner && isClearCachePressed.value) return true;
        return (item.routeName === routeName || isChild) && !isClearCachePressed.value;
    }
    function resetClearCache() {
        isClearCachePressed.value = false;
        clearCacheModalId = null;
    }
    function clearLocalStorage() {
        if (isClearCachePressed.value) return;
        isClearCachePressed.value = true;
        const { id, subscribe } = showDialog({
            presetName: PresetName.CONFIRM,
            options: {
                priority: DialogPriority.notification,
                title: $translate('WEB2_CLEAR_CACHE').value,
                confirmMessage: $translate('WEB2_CLEAR_CACHE_MESSAGE').value,
                buttons: [
                    {
                        label: $translate('WEB2_CLEAR').value
                    },
                    {
                        label: $translate('WEB2_MOBILE_CANCEL').value
                    }
                ],
                dataTestId: 'clear-cache'
            }
        });
        clearCacheModalId = id;
        subscribe({
            [DialogAction.CONFIRM]: ()=>{
                const idSuccessModal = 'successModal';
                storageManager.clearCache();
                const instance = getCurrentInstance();
                instance?.proxy?.$forceUpdate();
                showDialog({
                    id: idSuccessModal,
                    presetName: PresetName.ALERT_SUCCESS,
                    options: {
                        title: $translate('WEB2_CLEAR_CACHE_SUCCESS').value,
                        fullHeight: true,
                        dataTestId: 'clear-cache-success'
                    }
                }).promise.then(resetClearCache);
                clearCacheModalId = idSuccessModal;
            },
            [DialogAction.MODAL_CLOSE]: resetClearCache
        });
    }
    function handleClick(data) {
        const menuItem = data.payload.level === MenuLevel.ONE ? menuItemL1ById(data.payload.id) : null;
        if (menuItem?.id === logoutId) userStore.signOut();
        if (menuItem?.props?.isCacheCleaner) clearLocalStorage();
        if (!menuItem?.props?.isCacheCleaner && clearCacheModalId) {
            closeDialog(clearCacheModalId);
            resetClearCache();
        }
        if (usePinCodeStore().step) usePinCodeStore().setStepWithLS(null);
    }
    const bonusesCount = computed(()=>sportBonuses.value.length + egsBonuses.value.length);
    const navigationItems = computed(()=>[
            ...profileSidebarNavigation.value.filter((item)=>{
                if (item.routeName === RouteName.REFERRAL_PROGRAM) return !!referralProgramAvailability.value?.available;
                return true;
            }).reduce((accumulator, navigationItem)=>{
                let item = {
                    ...navigationItem
                };
                if (navigationItem.routeName === RouteName.CUSTOMER_NOTIFICATIONS) item = Object.assign(item, {
                    badge: unreadCounter.value
                });
                if (navigationItem.routeName === RouteName.BONUSES) item = Object.assign(item, {
                    suffixText: getOptionalString(bonusesCount.value)
                });
                accumulator.push(item);
                return accumulator;
            }, []),
            {
                id: logoutId,
                caption: $translate('JSPNAV_USTAT_LOGOUT').value
            }
        ]);
    const menuItems = computed(()=>convertNavigationToSidebarMenu(navigationItems.value.filter((item)=>{
            if (item.props?.isCashback) return isCashbackParticipatingNotConfirmed.value;
            return true;
        }), {
            ...sidebarMapper,
            canOpen: ()=>false,
            active: l1ActiveLevel
        }, sidebarMapper));
    function menuItemL1ById(id) {
        for (const item of menuItems.value)if (item.id === id) return item;
        return null;
    }
    return {
        menuItems,
        handleClick
    };
}
