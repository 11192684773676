// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .responsible-gambling-quiz_rpKrM{display:flex;flex:1 0 auto}html.app__layout--desktop .responsible-gambling-quiz__question_abFvv{display:flex;flex:1 0 auto;flex-direction:column;justify-content:space-between;width:100%;padding:0 32px}html.app__layout--desktop .responsible-gambling-quiz__question-progress_skt9b{margin-bottom:24px}html.app__layout--desktop .responsible-gambling-quiz__step-title_byK7n{font-size:18px;font-weight:500;line-height:24px;letter-spacing:normal;margin-bottom:24px;color:var(--TextDefault)}html.app__layout--desktop .responsible-gambling-quiz__step-option_SeO_3{padding:0 12px}html.app__layout--desktop .responsible-gambling-quiz__buttons_VyIGH{display:flex;flex-direction:column;gap:20px;padding:32px 0}html.app__layout--desktop .responsible-gambling-quiz__results-list_VBycU{display:flex;flex-direction:column;gap:12px;padding:0 24px 24px}html.app__layout--desktop .responsible-gambling-quiz__results-button_uvm8w{margin-top:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"responsible-gambling-quiz": `responsible-gambling-quiz_rpKrM`,
	"responsible-gambling-quiz__question": `responsible-gambling-quiz__question_abFvv`,
	"responsible-gambling-quiz__question-progress": `responsible-gambling-quiz__question-progress_skt9b`,
	"responsible-gambling-quiz__step-title": `responsible-gambling-quiz__step-title_byK7n`,
	"responsible-gambling-quiz__step-option": `responsible-gambling-quiz__step-option_SeO_3`,
	"responsible-gambling-quiz__buttons": `responsible-gambling-quiz__buttons_VyIGH`,
	"responsible-gambling-quiz__results-list": `responsible-gambling-quiz__results-list_VBycU`,
	"responsible-gambling-quiz__results-button": `responsible-gambling-quiz__results-button_uvm8w`
};
export default ___CSS_LOADER_EXPORT___;
