// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bonus-withdrawals_zjcrR{padding:16px 16px 0}.bonus-withdrawals__jumbotron_BlgNH{display:flex;align-items:center;justify-content:center;width:100%;height:100%}.bonus-withdrawals__money_G2RwS{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;color:var(--BrandDefault)}.bonus-withdrawals__payments-sum-fast_bMhaZ{margin:8px 0 0}.bonus-withdrawals__button-wrapper_PjJDP{margin-top:20px}html.app__layout--desktop .bonus-withdrawals_zjcrR{padding:16px 32px 32px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bonus-withdrawals": `bonus-withdrawals_zjcrR`,
	"bonus-withdrawals__jumbotron": `bonus-withdrawals__jumbotron_BlgNH`,
	"bonus-withdrawals__money": `bonus-withdrawals__money_G2RwS`,
	"bonus-withdrawals__payments-sum-fast": `bonus-withdrawals__payments-sum-fast_bMhaZ`,
	"bonus-withdrawals__button-wrapper": `bonus-withdrawals__button-wrapper_PjJDP`
};
export default ___CSS_LOADER_EXPORT___;
