import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import VAvatar from 'web/src/components/Avatar/VAvatar/VAvatar.vue';
import { AvatarSize } from 'web/src/components/Avatar/VAvatar/enums';
import PersonalInfo from 'web/src/modules/profile/components/PersonalInfo/PersonalInfo.vue';
import { CasinoLoyaltyProgramIconSizes } from 'web/src/modules/casino-loyalty-program/store/types';
import CasinoLoyaltyProgramIcon from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramIcon/CasinoLoyaltyProgramIcon.vue';
import { useProfileInfo } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ProfileInfo',
    props: {
        isLoyaltyEnabled: {
            type: Boolean
        },
        avatarSize: {
            default: AvatarSize.SIZE_44
        },
        imageSrc: {}
    },
    setup (__props) {
        const props = __props;
        const iconSize = CasinoLoyaltyProgramIconSizes.SIZE_36;
        const { handleClick } = useProfileInfo(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['profile-info'])
            }, [
                _createVNode(VAvatar, {
                    size: _ctx.avatarSize,
                    "is-loyalty-enabled": _ctx.isLoyaltyEnabled,
                    "image-src": _ctx.imageSrc,
                    onClick: _unref(handleClick)
                }, {
                    "egs-loyalty-program": _withCtx(()=>[
                            (_openBlock(), _createBlock(CasinoLoyaltyProgramIcon, {
                                key: 0,
                                size: _unref(iconSize),
                                "image-src": _ctx.imageSrc
                            }, null, 8, [
                                "size",
                                "image-src"
                            ]))
                        ]),
                    _: 1
                }, 8, [
                    "size",
                    "is-loyalty-enabled",
                    "image-src",
                    "onClick"
                ]),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['profile-info__text-container'])
                }, [
                    _createVNode(PersonalInfo)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ProfileInfo'
                ]
            ]);
        };
    }
});
